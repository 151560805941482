import logo from "./assets/logo-icon-transparent.png";
import logoWhite from "./assets/logo-white-transparent.png";
import "./App.css";
import { useState } from "react";
import OffCanvas from "./components/OffCanvas";

function App() {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  return (
    <div className="App">
      <header className="header d-flex align-items-center justify-content-between px-4 py-3">
        <img src={logo} className="app-logo" alt="logo" />
        <nav id="navbar" class={`navbar ${openMenu ? "navbar-mobile" : ""}`}>
          <ul onClick={() => setOpenMenu(false)}>
            <li>
              <a class="nav-link scrollto" href="#home">
                Home
              </a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#services">
                Services
              </a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#about">
                About
              </a>
            </li>
            <li>
              <a class="nav-link scrollto" href="#contact">
                Contact
              </a>
            </li>
            <li>
              <a
                class="getstarted scrollto text-decoration-none"
                href="#contact"
              >
                Get Started
              </a>
            </li>
          </ul>
          {openMenu ? (
            <i class="bi bi-list close-nav-toggle" onClick={toggleMenu}></i>
          ) : (
            <i class="bi bi-list mobile-nav-toggle" onClick={toggleMenu}></i>
          )}
        </nav>
      </header>
      <section id="home">
        <div className="hero-container d-flex align-items-center justify-content-center">
          <div className="hero-content text-center">
            <h1>Welcome to EU-AXIS</h1>
            <span className="fs-6">
              STUDY&nbsp;&nbsp;|&nbsp;&nbsp;WORK&nbsp;&nbsp;|&nbsp;&nbsp;SETTLE
            </span>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="services" className="p-5">
          <div className="d-flex align-items-start justify-content-center justify-content-md-between spaced-content why-us-container">
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title user-icon" />
              </div>
              <span className="info-title-sub fs-5">Personalized Guidance</span>
              <span className="info-title-content">
                Receive personalized guidance tailored to your unique goals,
                ensuring a smooth and customized experience throughout your
                study abroad journey
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title users-round" />
              </div>
              <span className="info-title-sub fs-5">
                Extensive Partner Network
              </span>
              <span className="info-title-content">
                Benefit from our extensive network of partner institutions,
                offering a diverse range of high-quality academic programs and
                destinations.
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title app-window" />
              </div>
              <span className="info-title-sub fs-5">Application Process</span>
              <span className="info-title-content">
                Navigate the application process seamlessly with our expert
                assistance, saving you time and reducing the stress associated
                with studying abroad.
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title circle-dollar-sign" />
              </div>
              <span className="info-title-sub fs-5">
                Transparent Cost Breakdown
              </span>
              <span className="info-title-content">
                Experience transparency in financial matters with a clear and
                detailed breakdown of costs, allowing you to plan and budget
                effectively.
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title clock-1" />
              </div>
              <span className="info-title-sub fs-5">24/7 Support</span>
              <span className="info-title-content">
                Enjoy peace of mind with our round-the-clock support, ensuring
                you have assistance whenever you need it, regardless of time
                zones.
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title book-check" />
              </div>
              <span className="info-title-sub fs-5">
                Visa and Documentation
              </span>
              <span className="info-title-content">
                Rely on our expertise in visa and documentation processes,
                making the often complex paperwork hassle-free and
                straightforward for you.
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title person-standing" />
              </div>
              <span className="info-title-sub fs-5">Cultural Integration</span>
              <span className="info-title-content">
                Ease into your new cultural environment with our support,
                providing guidance on cultural nuances and helping you integrate
                seamlessly into your host country.
              </span>
            </div>
            <div className="d-flex flex-column why-us-contents">
              <div className="d-flex align-items-center justify-content-center w-100">
                <i class="info-title plane-landing" />
              </div>
              <span className="info-title-sub fs-5">Post-Arrival Services</span>
              <span className="info-title-content">
                Our commitment extends beyond your arrival; we offer
                post-arrival services to ensure a smooth transition and a
                positive start to your academic journey.
              </span>
            </div>
          </div>
        </section>
        <section
          id="what-we-do"
          className="d-flex flex-column  align-items-center  gap-3 p-5"
        >
          <span className="fs-3 fw-bolder">WHAT WE DO</span>
          <ul className="what-we-do-li">
            <li>
              {" "}
              We at “EU-AXIS” provide a comprehensive range of services to make
              your transition easier. From advising on the best countries and
              cities to pursue your ambitions, to providing job-search
              assistance and visa processing, we have you covered.
            </li>
            <li>
              {" "}
              We are a team of passionate experts dedicated to helping you
              realize your dreams of living and working abroad. We know how
              overwhelming the process can be and that’s why we are here to
              help.
            </li>
            <li>
              {" "}
              Our mission is to provide you with the resources and support you
              need to make your transition to a new country easier and smoother.
              We are here to help you every step of the way and to ensure you
              have the best possible experience.
            </li>
          </ul>
        </section>
        <section id="choose-destination" className="p-5">
          <h2>Choose your dream destination with EU-AXIS</h2>
          <span className="fs-6">
            We take pride in our tailored approach, providing country-specific
            content to ensure our clients receive precise and relevant
            information for their unique immigration journey. Discover the
            possibilities with our comprehensive guidance designed for each
            destination.
          </span>
          <div className="d-flex align-items-center justify-content-between flag-container mt-5 py-3">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="flag-poland flag" />
              <span>Poland</span>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="flag-germany flag" />
              <span>Germany</span>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="flag-slovakia flag" />
              <span>Slovakia</span>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="flag-uk flag" />
              <span>UK</span>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="flag-france flag" />
              <span>France</span>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <i className="flag-lithuania flag" />
              <span>Lithuania</span>
            </div>
          </div>
        </section>
        <section
          id="about"
          className="d-flex flex-column  align-items-center  gap-3 p-5"
        >
          <span className="fs-3 fw-bolder about-us pb-3">ABOUT US</span>
          <span className="fs-6 about-us-info">
            EU-AXIS a limited liability company with its head office in Gdańsk ,
            street Jana Heweliusza 11/811 , post code 80-890 registered in the
            register of entrepreneurs kept by the District court for
            Gdańsk-Północ in Gdańsk, 7th commercial division of the national
            court register under KRS number 0001081863, with NIP 5833498848 and
            Regon 527473199
          </span>
          <span className="about-us-info">Our team can assist you with</span>
          <ul className="about-us-info">
            <li>Abroad education and admission guidance </li>
            <li>Job placements </li>
            <li>Immigration support</li>
            <li>Visa assistance</li>
            <li>Pre-departure & post-arrival support</li>
          </ul>
        </section>

        <section
          id="metrics"
          className="d-flex flex-column  align-items-center  gap-3 p-5"
        >
          <span className="fs-3 fw-bolder">Why EU-AXIS?</span>
          <span className="fs-5">
            Your needs and satisfaction are at the forefront of our immigration
            services.
          </span>
          <div className="d-flex align-items-center justify-content-between gap-5 w-100 mt-4 flex-wrap">
            <div className="d-flex flex-column align-items-center">
              <i className="icon-students s-64" />
              <span className="fw-bold fs-">150+</span>
              <span className="fs-6">Students</span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <i className="icon-employee s-64" />
              <span className="fw-bold">500+</span>
              <span className="fs-6">Employees</span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <i className="icon-application s-64" />
              <span className="fw-bold">100+</span>
              <span className="fs-6">Immigration</span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <i className="icon-university s-64" />
              <span className="fw-bold">50+</span>
              <span className="fs-6">Universities</span>
            </div>
            <div className="d-flex flex-column align-items-center">
              <i className="icon-globe s-64" />
              <span className="fw-bold">20+</span>
              <span className="fs-6">Countries</span>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <section
          id="contact"
          className="d-flex w-100 d-flex align-items-center justify-content-center p-5 flex-column"
        >
          <img src={logoWhite} className="brand-icon-white" alt="logo" />
          <span
            className="mt-2 pt-1 mb-0 fs-6 fw-bold"
            style={{ color: "#5cb874" }}
          >
            KRS - 0001081863 | NIP - 5833498848 | REGON - 527473199
          </span>
          <div className="d-flex align-items-start justify-content-start justify-content-md-between w-100 mt-5 pt-3 flex-wrap gap-5">
            <div class="social-links d-flex align-items-center justify-content-center gap-3 mt-4">
              <a href="#" class="facebook">
                <i class="bx bxl-facebook"></i>
              </a>
              <a href="#" class="instagram">
                <i class="bx bxl-instagram"></i>
              </a>
              <a href="#" class="linkedin">
                <i class="bx bxl-linkedin"></i>
              </a>
            </div>
            <span className="fs-6">
              <b>Head Office</b>
              <br />
              EU-AXIS SPÓŁKA Z
              <br />
              OGRANICZONĄ
              <br />
              ODPOWIEDZIALNOŚCIĄ
              <br />
              ul. Jana Heweliusza 11 lok. 811,
              <br />
              80-890 Gdańsk,
              <br />
              Poland
            </span>
            <div class="d-flex align-items-start justify-content-start gap-3 mt-4 flex-column contact-us">
              <div className="d-flex flex-row align-items-center justify-content-start">
                <i className="icon-phone me-2" />
                <span>+48 503 392 578</span>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start">
                <i className="icon-support me-2" />
                <a
                  href="mailto:biuro@eu-axis.com"
                  className="text-decoration-none mail-link"
                >
                  biuro@eu-axis.com
                </a>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-start">
                <i className="icon-mail me-2" />
                <a
                  href="mailto:apply@eu-axis.com"
                  className="text-decoration-none mail-link"
                >
                  apply@eu-axis.com
                </a>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
}

export default App;
